import React from "react";
import { auth } from "../../firebase";
import { 
  FaNewspaper, FaEdit, FaTrash, FaGift, FaPencilAlt, FaUserPlus, 
  FaUserMinus, FaBell, FaSignOutAlt, FaUsers, FaCog 
} from "react-icons/fa";
import { IoMdAdd, IoMdRemove } from "react-icons/io";

import RongvePostNews from "./RongvePostNews";
import RongveChangeNews from "./RongveChangeNews";
import RongveDeleteNews from "./RongveDeleteNews";
import RongvePostMemberBenefits from "./RongvePostMemberBenefits";
import RongveChangeMemberBenefits from "./RongveChangeMemberBenefits";
import RongveDeleteMemberBenefits from "./RongveDeleteMemberBenefits";
import RongveAddClub from "./RongveAddClub";
import RongveDeleteClub from "./RongveDeleteClub";
import RongveAddCoach from "./RongveAddCoach";
import RongveDeleteCoach from "./RongveDeleteCoach";
import RongvePushNotifications from "./RongvePushNotifications";
import RongvePostForTrainers from "./RongvePostForTrainers.tsx";
import RongveDeleteForTrainers from "./RongveDeleteForTrainers.tsx";
import RongveChangeForTrainers from "./RongveChangeForTrainers.tsx";

import WebnowLogo from './WebnowSingleColorRich.png';
import RongveLogo from './RongveKlubb.png';

const styles = {
  adminContainer: {
    maxWidth: "1200px",
    margin: "0 auto",
    padding: "20px",
  },
  logoContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: "30px",
  },
  logo: {
    maxHeight: "120px",
    width: "auto",
  },
  logo2: {
    maxHeight: "180px",
    width: "auto",
  },
  categoryContainer: {
    marginBottom: "30px",
  },
  categoryTitle: {
    fontSize: "22px",
    fontWeight: "bold",
    marginBottom: "15px",
    padding: "5px 10px",
    borderRadius: "5px",
    color: "#3498db", // Blå tekstfarge
    display: "flex",
    alignItems: "center",
  },
  categoryIcon: {
    marginRight: "8px",
    fontSize: "24px",
    color: "#3498db",
  },
  adminButtonGrid: {
    display: "grid",
    gridTemplateColumns: "repeat(auto-fill, minmax(200px, 1fr))",
    gap: "15px",
  },
  adminButton: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    padding: "15px",
    backgroundColor: "#4A90E2",
    color: "white",
    border: "none",
    borderRadius: "8px",
    cursor: "pointer",
    transition: "background-color 0.3s ease",
  },
  adminButtonIcon: {
    fontSize: "24px",
    marginBottom: "10px",
  },
  adminContent: {
    marginTop: "20px",
  },
};

const AdminButton = ({ icon: Icon, text, onClick }) => (
  <button style={styles.adminButton} onClick={onClick}>
    <Icon style={styles.adminButtonIcon} />
    <span>{text}</span>
  </button>
);

const CategorySection = ({ title, icon: Icon, children }) => (
  <div style={styles.categoryContainer}>
    <h2 style={styles.categoryTitle}>
      {Icon && <Icon style={styles.categoryIcon} />}
      {title}
    </h2>
    <div style={styles.adminButtonGrid}>
      {children}
    </div>
  </div>
);

export default function Rongve() {
  const [active, setActive] = React.useState("");

  const handleLogout = () => {
    auth.signOut();
    window.location.href = "/";
  };

  const renderContent = () => {
    switch (active) {
      case "postNews":
        return <RongvePostNews setActive={setActive} />;
      case "changeNews":
        return <RongveChangeNews setActive={setActive} />;
      case "deleteNews":
        return <RongveDeleteNews setActive={setActive} />;
      case "postMemberBenefits":
        return <RongvePostMemberBenefits setActive={setActive} />;
      case "changeMemberBenefits":
        return <RongveChangeMemberBenefits setActive={setActive} />;
      case "deleteMemberBenefits":
        return <RongveDeleteMemberBenefits setActive={setActive} />;
      case "postForTrainers":
        return <RongvePostForTrainers setActive={setActive} />;
      case "changeForTrainers":
        return <RongveChangeForTrainers setActive={setActive} />;
      case "deleteForTrainers":
        return <RongveDeleteForTrainers setActive={setActive} />;
      case "addClub":
        return <RongveAddClub setActive={setActive} />;
      case "deleteClub":
        return <RongveDeleteClub setActive={setActive} />;
      case "addCoach":
        return <RongveAddCoach setActive={setActive} />;
      case "deleteCoach":
        return <RongveDeleteCoach setActive={setActive} />;
      case "pushNotifications":
        return <RongvePushNotifications setActive={setActive} />;
      default:
        return null;
    }
  };

  return (
    <div style={styles.adminContainer}>
      {active === "" ? (
        <>
      <div style={styles.logoContainer}>
        <img src={WebnowLogo} alt="Webnow Logo" style={styles.logo} />
        <h1>Velkommen tilbake!</h1>
        <img src={RongveLogo} alt="Rongve Klubb Logo" style={styles.logo2} />
      </div>
      
          <CategorySection title="Nyheter:" icon={FaNewspaper}>
            <AdminButton icon={FaNewspaper} text="Legg ut nyhet" onClick={() => setActive("postNews")} />
            <AdminButton icon={FaEdit} text="Endre nyhet" onClick={() => setActive("changeNews")} />
            <AdminButton icon={FaTrash} text="Slett nyhet" onClick={() => setActive("deleteNews")} />
          </CategorySection>
          
          <CategorySection title="Medlemsfordeler:" icon={FaGift}>
            <AdminButton icon={FaGift} text="Legg ut medlemsfordel" onClick={() => setActive("postMemberBenefits")} />
            <AdminButton icon={FaPencilAlt} text="Endre medlemsfordel" onClick={() => setActive("changeMemberBenefits")} />
            <AdminButton icon={FaTrash} text="Slett medlemsfordel" onClick={() => setActive("deleteMemberBenefits")} />
          </CategorySection>
          
          <CategorySection title="For trenere:" icon={FaUsers}>
            <AdminButton icon={FaNewspaper} text="Legg ut nyhet" onClick={() => setActive("postForTrainers")} />
            <AdminButton icon={FaEdit} text="Endre nyhet" onClick={() => setActive("changeForTrainers")} />
            <AdminButton icon={FaTrash} text="Slett nyhet" onClick={() => setActive("deleteForTrainers")} />
          </CategorySection>
          
          <CategorySection title="Klubber og trenere:" icon={FaCog}>
            <AdminButton icon={IoMdAdd} text="Legg til klubb" onClick={() => setActive("addClub")} />
            <AdminButton icon={IoMdRemove} text="Slett klubb" onClick={() => setActive("deleteClub")} />
            <AdminButton icon={FaUserPlus} text="Legg til trener" onClick={() => setActive("addCoach")} />
            <AdminButton icon={FaUserMinus} text="Slett trener" onClick={() => setActive("deleteCoach")} />
          </CategorySection>
          
          <CategorySection title="Andre funksjoner:" icon={FaCog}>
            <AdminButton icon={FaBell} text="Push notifications" onClick={() => setActive("pushNotifications")} />
            <AdminButton icon={FaSignOutAlt} text="Logg ut" onClick={handleLogout} />
          </CategorySection>
        </>
      ) : (
        <div style={styles.adminContent}>
          {renderContent()}
        </div>
      )}
    </div>
  );
}
