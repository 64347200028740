import React from "react";

import ActionButtons from "../../components/ActionButtons";

import { storageRongve, firestoreRongve } from "../../firebase";

import Axios from "axios";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { FaNewspaper, FaUsers, FaBell, FaImage, FaHeading, FaCalendarAlt } from 'react-icons/fa';
import { styles } from './Styles';


const otherCategories = ["Trener", "Forelder", "Spiller"];

export default function RongvePostNews(props) {
  const [users, setUsers] = React.useState([]);
  const [alerts, setAlerts] = React.useState([]);
  const [push, setPush] = React.useState(false);
  const [selectedClubs, setSelectedClubs] = React.useState([]);
  const [clubs, setClubs] = React.useState([]);
  const [selectAll, setSelectAll] = React.useState(false);
  const [content, setContent] = React.useState("");

  const getClubInfo = async () => {
    const logoRef = storageRongve.ref("clubLogos/");
    const newClubs = [];
    const res = await logoRef.listAll();
    await Promise.all(
      res.items.map(async (itemRef) => {
        newClubs.push(itemRef.name);
      })
    );

    newClubs.forEach((club, index) => {
      newClubs[index] = club.slice(0, -4);
    });

    setClubs(newClubs);
  };

  React.useEffect(() => {
    getClubInfo();
    getUsers();
  }, []);

  const isSelected = (item) => selectedClubs.includes(item) || (selectAll && item !== "Alle");

  const handleClubSelection = (club) => {
    if (selectedClubs.includes(club)) {
      setSelectedClubs(selectedClubs.filter((id) => id !== club));
    } else {
      setSelectedClubs((prev) => [...prev, club]);
    }
  };

  const handleSelectAll = () => {
    if (!selectAll) {
      setSelectedClubs([...clubs, ...otherCategories]);
      setSelectAll(true);
      setSelectedClubs((prev) => [...prev, "Alle"]);
      const checkboxes = document.querySelectorAll(
        'input[type="checkbox"]:not(#selectAll)'
      );
      checkboxes.forEach((checkbox) => {
        checkbox.checked = false;
      });
    } else {
      setSelectedClubs([]);
      setSelectAll(false);
    }
  };
  const postNews = async () => {
    const image = document.getElementById("image").files[0];
    var imageBase64 = null;
    if (image) {
      imageBase64 = await new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(image);
        reader.onload = () => resolve(reader.result.slice(22));
        reader.onerror = (error) => reject(error);
      });
    }
    const title = document.getElementById("title").value;
    const date = document.getElementById("date").value;

    if (title && content && date) {
      firestoreRongve
        .collection("news")
        .add({
          image: imageBase64,
          title: title,
          content: content,
          date: date,
          clubs: selectedClubs,
        })
        .then(() => props.setActive(""))
        .catch((error) => {
          console.error("Error adding document: ", error);
        });
      handlePushNotification();
    }
  };

  const getUsers = async () => {
    const usersRef = firestoreRongve.collection("users");
    const snapshot = await usersRef.get();
    const userList = [];
    snapshot.forEach((doc) => {
      const userData = doc.data();
      userList.push({ id: doc.id, ...userData });
    });
    setUsers(userList);
  };

  const handlePushNotification = () => {
    let pushTokens = [];
    const title = document.getElementById("title").value;

    users.forEach((user) => {
      if (user.expoPushToken && user.expoPushToken.length > 0) {
        const userClubIds = (user.clubIds || []).map((id) =>
          id.replace(".png", "")
        );

        const isForEveryone = selectedClubs.includes("Alle");

        const relevantClubs = userClubIds.filter((club) =>
          selectedClubs.includes(club)
        );
        const hasClub = relevantClubs.length > 0;
        const isOnlyRolesNews =
          selectedClubs.includes(user.userType) &&
          !selectedClubs.some((c) => clubs.includes(c));
        const isGeneralRoleNews =
          selectedClubs.includes(user.userType) && selectedClubs.length === 1;
        const isRoleAndClubNews =
          hasClub && selectedClubs.includes(user.userType);
        const isGeneralClubNews = hasClub && selectedClubs.length === 1;
        const isTrainerNews =
          user.trainer &&
          selectedClubs.includes("Trener") &&
          (hasClub || selectedClubs.length === 1);

        if (
          isForEveryone ||
          isGeneralRoleNews ||
          isRoleAndClubNews ||
          isGeneralClubNews ||
          isTrainerNews ||
          isOnlyRolesNews
        ) {
          pushTokens.push(user.expoPushToken);
        }
      }
    });

    pushTokens = new Set(pushTokens);
    pushTokens = JSON.stringify(Array.from(pushTokens));
    console.log(pushTokens);

    if (push) {
      setAlerts([...alerts, { title }]);

      Axios.post(
        `https://webnow-adminpanel.ew.r.appspot.com/send?pushTokens=${pushTokens}&title=Ny nyhet&body=${title}`
      ).then((response) => console.log(response.data));
    }
  };

  return (
    <div style={styles.container}>
      <h2 style={styles.header}>
        <FaNewspaper style={styles.headerIcon} /> Legg ut nyhet
      </h2>
      <form>
        <div style={styles.inputGroup}>
          <FaImage style={styles.inputIcon} />
          <input 
            type="file" 
            style={styles.fileInput} 
            id="image" 
          />
        </div>
        
        <div style={styles.inputGroup}>
          <FaHeading style={styles.inputIcon} />
          <input
            type="text"
            style={styles.input}
            id="title"
            placeholder="Tittel"
          />
        </div>
        
        <div style={styles.quillContainer}>
          <ReactQuill
            value={content}
            onChange={setContent}
            placeholder="Innhold"
            id="content"
            modules={styles.quillModules}
          />
        </div>
        
        <div style={styles.inputGroup}>
          <FaCalendarAlt style={styles.inputIcon} />
          <input 
            type="date" 
            style={styles.input} 
            id="date" 
          />
        </div>
        
        <div style={styles.categorySection}>
          <h3 style={styles.categoryHeader}>
            <FaUsers style={styles.categoryIcon} /> Velg målgrupper
          </h3>
          <div style={styles.selectionContainer}>
            <div 
              style={{
                ...styles.selectionBox,
                ...(selectAll ? styles.selectedBox : {})
              }}
              onClick={handleSelectAll}
            >
              Alle
            </div>
          </div>
          
          <h4 style={styles.subCategoryHeader}>Velg kategorier:</h4>
          <div style={styles.selectionContainer}>
            {otherCategories.map((category) => (
              <div 
                key={category}
                style={{
                  ...styles.selectionBox,
                  ...(isSelected(category) ? styles.selectedBox : {})
                }}
                onClick={() => handleClubSelection(category)}
              >
                {category}
              </div>
            ))}
          </div>
          
          <h4 style={styles.subCategoryHeader}>Velg klubber:</h4>
          <div style={styles.selectionContainer}>
            {clubs.map((club) => (
              <div 
                key={club}
                style={{
                  ...styles.selectionBox,
                  ...(isSelected(club) ? styles.selectedBox : {})
                }}
                onClick={() => handleClubSelection(club)}
              >
                {club}
              </div>
            ))}
          </div>
        </div>
        
        <div style={styles.notificationContainer}>
          <FaBell style={styles.notificationIcon} />
          <div 
            style={{
              ...styles.selectionBox,
              ...(push ? styles.selectedBox : {})
            }}
            onClick={() => setPush(!push)}
          >
            Send ut notifikasjoner
          </div>
        </div>
        
        <ActionButtons
          setActive={props.setActive}
          onClick={postNews}
        />
      </form>
    </div>
  );
}

